<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Cambios de horario</span>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small 
              dark 
              color="green"
              @click="exportarInscripciones()"
              tile
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>
            <v-row>
              <v-col cols="12" md="3" >
                <v-text-field
                  filled
                  single-line
                  label="Fecha"
                  type="date"
                  hide-details
                  v-model="fecha"
                  dense
                  @keyup.enter="initialize()"
                  clearable
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6">
                <label></label>
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  single-line
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                ></v-text-field>
              </v-col>
            </v-row>

	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="cambiohorario"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.estatus="{ item }">
							      <v-chip v-if="item.estatus == 0" small color="grey"  dark>Pendiente</v-chip>
							      <v-chip v-if="item.estatus == 1" small color="green" dark>Aceptado</v-chip>
							      <v-chip v-if="item.estatus == 2" small color="black" dark>Rechazado</v-chip>
							    </template>
							  

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idsolicitud_cambio_horario }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>

          	<v-col cols="12">
	  		  		<v-autocomplete 
	  		  			filled 
	  		  			dense 
	  		  			clearable 
	  		  			v-model="editedItem.id_usuario" 
	  		  			:items="usuarios"
								label="Selecciona un usuario" 
								hide-details
								item-text="nombre_completo" 
								item-value="id_usuario"
							>
							</v-autocomplete>
  		  		</v-col>

  		  		<br/>
  		  		<br/>

  		  		<v-row class="pl-3" v-if="editedIndex < 0">
  		  			<v-col cols="12">
  		  			<label>Llena los siguientes y da clic en agregar para agregar los datos a la solicitud</label>		  				
  		  			</v-col>
	          	<v-col cols="12" md="6">
	              <v-text-field
	                v-model="fecha"
	                label="Fecha"
	                filled
	                hide-details
	                dense
	                type="date"
	              ></v-text-field>
	            </v-col>


	            <v-col cols="12" md="6">
	              <v-btn 
	              	color="orange"
	              	dark
	              	@click="agregarHorario()"
	              	small
	              	tile
	              >
	            		<v-icon small>mdi-plus</v-icon>
	            	</v-btn>
	            </v-col>

  		  		</v-row>

	          <v-col cols="12" md="6" v-if="editedIndex < 0">
              <v-text-field
                v-model="hora_inicio"
                label="Hora inicio"
                filled
                hide-details
                dense
                type="time"
              />
            </v-col>
	          <v-col cols="12" md="6" v-if="editedIndex < 0">
              <v-text-field
                v-model="hora_final"
                label="Hora final"
                filled
                hide-details
                dense
                type="time"
              ></v-text-field>
            </v-col>

            <v-col cols="12" v-if="editedIndex < 0">
            	<v-textarea
            		v-model="motivo"
            		filled
            		hide-details
            		dense
            		auto-grow
            		:rows="3"
            		label="Motivo"
            	/>
            </v-col>

            <v-col cols="12">
            	<v-data-table
						    :headers="headers2"
						    :items="horarios"
						    class="elevation-0"
						    :search="search"
						    :mobile-breakpoint="100"
		            dense
						  >
						    <template v-slot:item.actions="{ item }">
						    	<v-btn 
						    		color="error" 
						    		x-small
            				v-if="editedIndex < 0"
						    		@click="deleteItem2(item)"
						    		class="mr-2"
						    	>
							      <v-icon small> mdi-delete</v-icon>
						    	</v-btn>
						    </template>
						  </v-data-table>
            </v-col>

            <v-col cols="12" v-if="editedItem.motivo_rechazo">
            	<v-textarea
            		v-model="editedItem.motivo_rechazo"
            		filled
            		hide-details
            		readonly
            		dense
            		auto-grow
            		:rows="3"
            		label="Motivo"
            	/>
            </v-col>

          </v-row>
        </v-card-text>

        <v-card-actions >
          <v-btn
            color="black"
            tile
            dark
            small
            @click="close"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="black"
            class="ml-2"
            tile
            dark
            small
            @click="rechazar = true"
            v-if="!rechazar && editedItem.estatus == 0"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Rechazar
          </v-btn>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
            v-if="!rechazar && editedItem.estatus == 0"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Aceptar
          </v-btn>
        </v-card-actions>

        <v-card-actions v-if="rechazar">
          <v-textarea
        		v-model="motivo_rechazo"
        		filled
        		hide-details
        		dense
        		auto-grow
        		:rows="3"
        		label="Motivo"
        	/>
        </v-card-actions>

        <v-card-actions v-if="rechazar">
          <v-btn
            color="black"
            tile
            dark
            small
            @click="rechazar = false"
          >
            <v-icon small left>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            class=""
            tile
            dark
            small
            @click="save"
          >
            <v-icon small left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      planteles:[],
      
      editedIndex: -1,

      editedItem: {
        idsolicitud_cambio_horario:0,
				id_solicita:'',
				id_autoriza: null,
				id_usuario:null,
				estatus:0,
				deleted:0,
				fecha_creacion:null,
      },

      defaultItem: {
        idsolicitud_cambio_horario:0,
				id_solicita:'',
				id_autoriza: null,
				id_usuario:null,
				estatus:0,
				deleted:0,
				id_usuario_ultimo_cambio:0,
				fecha_creacion:null,
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      cambiohorario: [],
      headers: [
        { text: 'ID'              , value: 'idsolicitud_cambio_horario'    },
        { text: 'Solicitó'        , value: 'solicito'                      },
        { text: 'Aceptó'          , value: 'acepto'                        },
        { text: 'Trabajador'      , value: 'trabajador'                    },
        { text: 'Estatus'         , value: 'estatus'                       },
        { text: 'Fecha creación'  , value: 'fecha_creacion'                },
        { text: 'Actions'         , value: 'actions', sortable: false },
      ],

			usuarios:[],


			fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			hora_inicio: null,
			hora_final: null,
			motivo: null,

			headers2: [
        { text: 'Fecha'       , value: 'fecha'       },
        { text: 'Hora inicio' , value: 'hora_inicio' },
        { text: 'Hora final'  , value: 'hora_final'  },
        { text: 'Motivo'      , value: 'motivo'      },
        { text: 'Actions'     , value: 'actions', sortable: false },
      ],
			horarios:[],

			rechazar: false,
			motivo_rechazo: '',
      organigrama:[],


    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar información' : 'Editar información'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.getOrganigrama( )
      await this.initialize()
      await this.getUsuarios( )
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.cambiohorario = []

        const payload = {
          fecha: this.fecha
        }
        return this.$http.post('cambio.horario.list', payload).then(response=>{
          this.cambiohorario = response.data.filter( el => el.id_solicita != this.getdatosUsuario.iderp )

          let puesto          = this.getdatosUsuario.puesto
          let mapIdPlanteles  = this.getdatosUsuario.planteles.map((registro) => registro.idplantel);

          if( this.getdatosUsuario.iderp == 568 ){ puesto = 'Jefe de Operaciones' }

          if( this.getdatosUsuario.iderp == 626 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [32,21,4,6] 
          }

          if( this.getdatosUsuario.iderp == 7 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [29,24,1,23] 
          }

          if( this.getdatosUsuario.iderp == 526 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [22,2,3] 
          }

          if( this.getdatosUsuario.iderp == 1314 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [25,28] 
          }

          if( this.getdatosUsuario.iderp == 1312 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [27,5,31,17] 
          }

          if( this.getdatosUsuario.iderp == 382 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [16,7,11,9] 
          }

          if( this.getdatosUsuario.iderp == 161 ){
            puesto         = 'COORDINADOR ZONA' 
            mapIdPlanteles = [14,8,12,10] 
          }

          if( this.getdatosUsuario.iderp == 28 ){
            puesto = 'Jefe Finanzas'
          }

          let puestosDependientes = this.organigrama.filter( el => el.puesto_jefe == puesto ).map((registro) => { return registro.idpuesto_auxiliar })


          if( this.getdatosUsuario.iderp == 954 ){
            puestosDependientes = this.organigrama.map((registro) => { return registro.idpuesto_auxiliar })
          }

          if( this.getdatosUsuario.puesto == 'Encargada de sucursal' && this.getdatosUsuario.iderp != 568 ){
            this.cambiohorario = this.cambiohorario.filter( el => mapIdPlanteles.includes( el.id_plantel ) )
          }else if( puesto == 'COORDINADOR ZONA' ){
            this.cambiohorario = this.cambiohorario.filter( el => mapIdPlanteles.includes( el.id_plantel ) )
          }else if( puesto == 'ENCARGADA DE SUCURSAL' ){
            this.cambiohorario = this.cambiohorario.filter( el => puestosDependientes.includes( el.id_puesto ) && mapIdPlanteles.includes( el.id_plantel )  )
          }else{
            console.log('ouhpo')
            this.cambiohorario = this.cambiohorario.filter( el => puestosDependientes.includes( el.id_puesto ) )
          }

          if( [334,1,966].includes( this.getdatosUsuario.iderp ) ){ this.cambiohorario = response.data }

        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getOrganigrama( ){
        this.organigrama = [];
        this.overlay = true;

        this.$http.get("usuarios.organigrama").then((response) => {
          this.organigrama = response.data;
          this.overlay = false;
        }).catch((error) => {
          this.validarError( error.response.data.message )
        });
      },

      editItem (item) {
        this.editedIndex = this.cambiohorario.indexOf(item)
        this.editedItem = Object.assign({}, item)

        this.horarios = []

        if( this.editedItem.horarios.length ){
        	this.horarios = this.editedItem.horarios
        }

        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.cambiohorario.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItem2( item ){
      	let editedIndex = this.cambiohorario.indexOf(item)
        this.horarios = this.horarios.filter( el => el.fecha != item.fecha )
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('cambio.horario.update/' + this.editedItem.idsolicitud_cambio_horario, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
	      this.cargar = true

	      this.editedItem.estatus = this.rechazar ? 2 : 1
	      this.editedItem.motivo_rechazo = this.rechazar ? this.motivo_rechazo : ''
	      this.editedItem.id_autoriza    = this.getdatosUsuario.iderp

        // Lo mandapos por el EP
        this.$http.put('cambio.horario.update/' + this.editedItem.idsolicitud_cambio_horario, this.editedItem).then(response=>{
        	this.validarSuccess( response.data.message )
      		this.close()
        	this.initialize()
        	this.rechazar = false
        }).catch( error =>{
	        this.validarError( error.response.data.message )
	      }).finally( () => { this.cargar = false })

      },

      getUsuarios( ){
      	this.usuarios = []
    		this.$http.get('usuarios.all.erp').then(response=>{
    			for(const i in response.data){
    				response.data[i].nombre_completo = response.data[i].nombre_completo.toUpperCase() 
    			}
    			this.usuarios = response.data
    		}).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      agregarHorario( ){

      	this.horarios.push({
      		fecha       : this.fecha, 
      		hora_inicio : this.hora_inicio,
      		hora_final  : this.hora_final,
      		motivo      : this.motivo
      	})

      	this.fecha       = null
				this.hora_inicio = null
				this.hora_final  = null
				this.motivo      = ''
      }
    },
  }
</script>


